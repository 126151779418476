// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$enable-shadows: false;
$imp: #807db9 !important;
//== Fonts
$font-weight-boldest: 900 !default;
$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-normal: 400 !default;
$font-weight-thin: 300 !default;
$font-weight-base: $font-weight-normal !default;


//== Typography
$font-family-base: 'Poppins';

$font-size-initial: 16px;
$font-size-base: 1rem !default;
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-mini: 0.75rem !default;


//== Headlines
$headline-large: 1.875rem !default;
$headline-medium: 1.5rem !default;
$headline-small: 1rem !default;
$headline-font-weight: $font-weight-semi-bold !default;


//== Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6b859e !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$violet: #286AD9 !default;
$violet-dark: #2F33A7 !default;
$green: #278566 !default;
$blue: #00A5FF !default;
$dark-blue: #101C30 !default;
$orange: #FFA100 !default;
$orange-dark: #CE3939 !default;
$pink: #FF5668 !default;
$yellow: #FFC405 !default;
$font-label-color: #515760 !default;
$cyan: #41D5E2 !default;
$default: #16365f !default;
$gray-dark :#545961 !default;
$base-dark-gray: #6b859e !default;
$base-middle-gray: #7F838A !default;
$base-light-gray: #F2F6F8 !default;
$base-gray-bg: #F2F4F6 !default;
$heading-font-color: #222730 !default;

$theme-colors: (primary: $violet,
  secondary: $base-middle-gray,
  secondary-red: $pink,
  secondary-yellow: $yellow,
  secondary-cyan: $cyan,
  success: $green,
  info: $blue,
  warning: $orange,
  danger: $orange-dark,
  primary-hover: $violet-dark,
  dark: $gray-700,
  default: $default,
  dark-gray: $base-dark-gray,
  middle-gray: $base-middle-gray,
  light-gray: $base-light-gray,
  // experimental added white color to theme-colors
  white: $white,
);

$body-bg: $base-light-gray !default;
$icon-color: $default !default;
$text-color: $default !default;
$text-muted: $base-dark-gray !default;
$text-gray: $gray-dark !default;
$widget-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);

//== Components
$layout-padding: 24px 24px 68px;
$content-padding: 24px;
$content-padding-sm: 10px;
$main-margin: 12px;

//== Buttons
$button-padding: 8px 32px;
$border-radius: 8px !default;

//== Navbar
$navbar-margin: 24px;
$navbar-bg: $body-bg !default;
$navbar-height: 45px !default;
$navbar-padding: 24px 24px 8px;

//== Sidebar
$sidebar-width-open: 240px;
$sidebar-width-closed: 50px;
$sidebar-transition-time: 0.2s !default;

$sidebar-item-padding: 8px 10px !default;
$sidebar-sub-item-padding: 8px 40px;
$sidebar-color: $white !default;

//== Widgets
$widget-padding-vertical: 24px !default;
$widget-padding-horizontal: 32px !default;
$widget-bg-color: $white;
$widget-border-radius: 20px;


//== Forms
$input-focus-box-shadow: none;
//== Card 
$card-detail:$base-gray-bg;
//sas-colors
$primaryColor: #4d53e0;
$primaryColor1: #286AD9;
$primaryColor2: #48beff;
$primaryColor3: #016fb9;
$primaryColor4: #00314d;
$primaryColor5: #000022;
$primaryColor6: #3d9970;

$fieldsBorderColor: #cccccc;
$fieldsTextColor: #000000;
$fieldsBackgroundColor: #ffffff;

$purple: #d1efff;
$purple2: #48beff;
$purple3: #b3e8ff;
$purple4: #00314d;
$purple5: #5e4999;
$purple6: #6650a3;
$purple6: #6b54ab;
$purple7: #b3e4ff;
$purple8: #726496;
$lightPurple2: #e5f6ff;

//others

$gray: #646464;
$fontColorInput: #333333;
$Poppins: "Poppins";
$fieldsBackgroundColor: #ffffff $imp;
$fontColorSidebar:#CEE0FF;
$permissions-font: normal normal normal 18px/27px Poppins;
$formHeading:normal normal medium 20px/30px Poppins;
$login-font:italic normal 600 20px/30px Poppins;
$forgot-font:normal normal 600 14px/21px Poppins;