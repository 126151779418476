@import "../../../styles/helpers";

.headerLink {
  overflow-x: hidden;
  margin-right: 17px;
  margin-bottom:0.7rem;
  // background-color: #286AD9;
  border-radius: 1rem;

  a {
    display: flex;
    justify-content: left;
    align-items: center;
    text-decoration: none;
    color: $fontColorSidebar;
    cursor: pointer;
    font-size: 1rem;
    font: normal small 600 16px/25px Poppins;
    
    //  font-weight: $font-weight-semi-bold;
  }

  > a {
    position: relative;
    padding: $sidebar-item-padding;
    white-space: nowrap;
    border-radius: .25rem;
    

    @media (max-width: breakpoint-max(sm)) {
      padding: 13px 32px;
    }
  }

  .icon {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    max-height: 16px;
    max-width: 16px;
    text-align: center;
  }

  .badge {
    display: flex;
    margin-left: auto;
    padding: 7px;
    width: 24px;
    height: 24px;
    font-size: $font-size-sm;
    line-height: 8px;
    font-weight: $font-weight-normal;
    border-radius: 50%;
  }

  @media (max-width: breakpoint-max(sm)) {
    margin-right: 0;
  }
}

a.headerLinkActive {
  background-color: #5c93f1;
  border-radius: .25rem;
  // font-weight: $font-weight-normal;
  font-size: 1rem;
  font: normal normal normal 16px/25px Poppins;
  // margin-left: 20px;
  color: $white;

  i {
    color: white !important;
  }

  &:hover {
    color: $white;
  }

  svg {
    path {
      // fill: $white;
      stroke: $white;
    }
  }
}

.collapsed .caret {
  transform: rotate(180deg);
}

.caret {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 8px;

  @include transition(transform 0.3s ease-in-out);
}

.panel {
  border: none;
  box-shadow: none;
  margin: 0;
  border-radius: 0;
  color: white;
 margin-bottom: 3px;

  ul {
    li {
      list-style: none;
    }

    a {
      //  padding: $sidebar-sub-item-padding;
      // font-size: $font-size-base;
      // font-weight: $font-weight-normal;
      font: normal normal normal 16px/25px Poppins;
      padding: 3px;
      color: $fontColorSidebar;

      &:hover {
        color: $white;
      }
    }


    a.headerLinkActive {
      color: $white;
    }

    @media (max-width: breakpoint-max(sm)) {
      padding-left: 16px;
    }
  }

  i {
    font-size: 8px;
  }
}
.activeChildern{
  margin-left: 16px;
  padding: 1px;
}
.activeChildernCollapse{
  margin-left: 4px;
  padding: 2px;
}