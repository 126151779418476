@import "../../styles/helpers.scss";
@import "../../assets/sass/importFiles";
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
.popoverHeadingText{
    font: normal normal medium 14px/21px Poppins;
    color: $base-middle-gray;
}
.popoverText{
    font: normal normal normal 16px/25px Poppins;
    color: $gray-dark;
    
}
.icons{
    display: inline-flex;
}
.errorAlert{
    border-radius: 4px;
    font-size: small;
    font: normal normal medium 14px/21px Poppins;
    color: white;
    background-color: #CE3939 !important;
}
.password {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 5px;
    background: #ddd;
    border-radius: 5px;
    width: 100%;
  }
  .password:before,
  .password:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: #ddd;
    border-style: solid;
    border-width: 0 1px 0;
    position: absolute;
    width: calc(25% - 1px );
    z-index: 10;
  }
  .password:before {
    left: calc(25% );
  }
  .password:after {
    right: calc(25%);
  }
   .strength-1::-webkit-progress-value {
    background-color: #CE3939;
    width: 100%;
  }
  .strength-2::-webkit-progress-value {
    background-color: #FFB534;
    width: 100%;
  }
   .strength-3::-webkit-progress-value {
    background-color: #278566;
    width: 100%; 
  }
 
.strength-4::-webkit-progress-value {
    /* background-color: #084c1eba;  */
    width: 100%; 
  }
   .strength-1,
  .strength-0s{
    color: #CE3939;
    display: flex;
    font-size: 11px;
    height: 7px;
    font-weight: 500;
    // width: 100%;
    margin-top: 5px;
  }
  .strength-2 {
    color: #FFB534;
    display: flex;
    font-size: 11px;
    height: 7px;
    // width: 100%;
    font-weight: 500;
    margin-top: 5px;
  }
   .strength-3 {
    color: #278566;
    display: flex;
    font-size: 11px;
    height: 7px;
    // width: 100%;
    font-weight: 500;
    margin-top: 5px;
  }
   .strength-4 {
    color: #084c49ba;
    display: flex;
    font-size: 11px;
    height: 7px;
    // width: 100%;
    font-weight: 500;
    margin-top: 5px;
    width: 100%;
  }
  progress{
      visibility: inherit;
  }
 