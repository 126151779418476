
.attendance-dashboard{
    background: #D5DEE2 0% 0% no-repeat padding-box!important;
    border: 15px solid #0000000D!important;
}
.dashboard-text{
    color: #545961;
    font: normal normal normal 18px/27px Poppins;
}
.react-datepicker-popper {
    z-index: 33;
  }
  .minHeight{
    min-height: 115px
  }
  .datepicker-position{
    max-width:200px;
    position: absolute;
    right:0
  }
  .monthly-card-loader{
      min-height: 315px;
      margin-top: 30px; 
  }
  .graph{
    min-height: 315px;
    margin-top: 30px
}