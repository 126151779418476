.progresser{
    position: relative;
    height: 10px;
    width: 100%;
    /* border: 10px solid #f4a261; */
    /* border-radius: 15px; */
}
.progresser .color{
    background-color: #15d323;
    width: 0px;
    height: 10px;
    border-radius: 15px;
    animation: progres 4s infinite linear;    
}
@keyframes progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}