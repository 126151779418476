.card {
    border-radius:  12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    border: 0 none;
  
    -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
      box-shadow 200ms ease;
    -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
      box-shadow 200ms ease;
    -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
      box-shadow 200ms ease;
    -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
      box-shadow 200ms ease;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
      box-shadow 200ms ease;
  
    .card-body {
      padding: 15px 15px 10px 15px;
  
      &.table-full-width {
        padding-left: 0;
        padding-right: 0;
      }
    }
  
    .card-header {
      &:not([data-background-color]) {
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;
  
      .card-title {
        margin-top: 10px;
      }
    }
  
   
    .image {
      overflow: hidden;
      height: 200px;
      position: relative;
    }
  
    .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 15px;
    }
  
    .numbers {
      font-size: 2em;
    }
  
    .big-title {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding-bottom: 15px;
    }
  
    label {
      font-size:0.8571em;
      margin-bottom: 5px;
      color: #9A9A9A;
    }
  
    .card-footer {
      background-color: transparent;
      border: 0;
  
      .stats {
        i {
          margin-right: 5px;
          position: relative;
          top: 0px;
          color: #66615B;
        }
      }
  
      .btn {
        margin: 0;
      }
    }
  
    &.card-plain {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
  
      .card-body {
        padding-left: 5px;
        padding-right: 5px;
      }
  
      img {
        border-radius:  12px ;
      }
    }
  }
  