@import "../../styles/helpers";

.root {
  display: flex;
  flex-direction: column;
  //width: $sidebar-width-open;
  width: 269px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-left: 16px;
  background-color: $dark-blue;
  overflow-y: auto;
  transition: height 1s;
  // transform: translateX(-$sidebar-width-open);


  @media print {
    display: none;
  }

  :global(.sidebar-hide) & {
    display: none;
  }
 

  @media (max-width: breakpoint-max(sm)) {

    position: static !important;
    // width: auto;
    margin: 12px 24px 0;
    width: 86%;
    border-radius: 8px;
    padding: 0;
    height: 0;
    transform: none;

    .logo,
    .sidebarAlerts,
    .sidebarLabels,
    .navTitle {
      display: none;
    }
  }
}

.sidebarOpen {
  @media (min-width: breakpoint-min(md)) {
    height: auto !important;
  }
}

.logo {
  display: flex;
  align-items: center;
  margin: 16px 16px  16px 1px;
  // width: 100%;
  justify-content: space-around;

  .title {
    margin-left: 12px;
    font-size: 20px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.nav {
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 57px;

  li > a > span {
    // display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }
}

.navTitle {
  margin: 32px 0 8px;
  font-size:$font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
  text-transform: uppercase;
}

.minWidth{
  display: flex;
  flex-direction: column;
  // width: $sidebar-width-open;
  // width: 72px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-left: 13px;
  background-color: $dark-blue;
  overflow-y: auto;
  transition: height 1s;
  // transform: translateX(-$sidebar-width-open);
}
.footerLogo{
  position: fixed !important;
  bottom: 0;
  left: 0;
  background-color:#3D495E;
  height: 55px;
  width: -webkit-fill-available;
  // padding: 16px;
  

}
.jazzLogo{
  margin-left: 2.5rem;
  margin-top: 0.4rem;
  margin-right:0.6rem;
}
.logoText{
  margin-top: 10px;
  color: #CEE0FF;
  size: 16px;
  font-weight: 600;
  font-family: "Poppins";
}
	
.footerLogoImage{
  position: fixed !important;
  bottom: 0;
  left: 0;
  background-color:#3D495E;
  height: 75px;
  width: -webkit-fill-available;
  // padding: 16px;

 }
 .jazzLogo{
  margin-left: 2.5rem;
  margin-top: 0.4rem;
  margin-right:0.6rem;
 }
 .logoText{
  margin-top: 10px;
  color: #CEE0FF;
  size: 16px;
  font-weight: 600;
  }
 .logoText
 {  margin-top: 16px;
   margin-left: 19px;
 }
 .stechlogo{
 margin-left: 5px;
 height: 23px;
 }
