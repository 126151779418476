.main-container {
    top: 378px;
    left: 862px;
    width: 348px;
    height: 83px;
    opacity: 1;
}


.modalBackground {
    border: dashed 5px
 rgba(0,0,0,0.2);
    height: 100%;
    padding: 79px !important;
    border-radius: 5em;
    background: #F2F5F9;
    margin: 24px;
}
.image-text {
    top: 378px;
    left: 882px;
    width: 52px;
    height: 23px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-25) var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-7f838a);
    text-align: left;
    font: normal normal medium 16px/25px Poppins;
    letter-spacing: 0px;
    color: #7F838A;
    opacity: 1;
}

/* BrowseImage */
.browse-button {
    top: 411px;
    left: 882px;
    width: 140px;
    height: 40px;
    border: 2px solid var(--unnamed-color-286ad9);
    border: 2px solid #286AD9;
    border-radius: 5px;
    opacity: 1;
}


/* CaputureImage */
.capture-image {
    top: 411px;
    left: 1042px;
    width: 148px;
    height: 40px;
    background: var(--unnamed-color-286ad9) 0% 0% no-repeat padding-box;
    border: 2px solid var(--unnamed-color-286ad9);
    background: #286AD9 0% 0% no-repeat padding-box;
    border: 2px solid #286AD9;
    border-radius: 5px;
    opacity: 1;
}

.capture-text {
    top: 421px;
    left: 1062px;
    width: 108px;
    height: 20px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 14px/21px var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal medium 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

/* model shape */
.Model-shape {
    border-radius: 30px;
}

.modal-lg {
    max-width: 65%;
}

.modal-dialog {

    margin: 0.5rem auto !important;

}

@media only screen and (max-width: 600px) {
    .modal-lg {
        max-width: 95% !important;
    }
}


.img-sops {
   
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-222730);
    text-align: left;
    font:  medium 20px/30px Poppins;
    font-size: large;
    letter-spacing: 0
}

.btn-cap {
    padding: 0;
    border: none !important;
    background: none !important;
    position: "absolute";
    padding: 0px 0px !important;
    color: #286AD9;
}

/* .modal-footer {
    display: block !important;
} */

/*Browse image */


.custom-file-upload {
    border: 3px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
    color: #286AD9;
    border-color: #286AD9;
    width: 9.25rem;

}




.custom-file::-webkit-file-upload-button {
    visibility: hidden;
}

#file-upload {
    display: none;
}
