@import "../../../styles/helpers";
@import "../../../assets/sass/importFiles";

// permission checkboxes
.permission-checkbox{
    font:$permissions-font;
    letter-spacing: 0px;
    color: $font-label-color;
    opacity: 1;
  }
