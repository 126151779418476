#example tr > *:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    min-width: 10rem;
    z-index: 1;
    border-right: solid 1px #D1D4D9;
  }
  
  #example tr > *:first-child::before {
    content: "";
    position: absolute;
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    // background-color: whitesmoke;
    z-index: -1;
  }

  #example tr:nth-child(2n+1) > td {
    background-color: #F2F6F8;
  }
  #example  tr:nth-child(2n) > td {
    background-color: #f8f8f8;
  }
  #example  tr:nth-child(n) > th {
    background-color:rgb(231, 235, 246);
  }