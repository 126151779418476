@import "../../assets/sass//importFiles";
.loadingMaskWrapper {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */

  justify-content: center;
  align-items: center;

  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100%;
  outline: none !important;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: default;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  .loading {
    padding: 8px;
    border-radius: 3px;
  }
  .loadingImg {
    width: 100px;
  }
  .loadingImageWrapper {
    padding: 0 0 4px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center 0;
  }
  .loadingText {
    color: #ffff;
    font: normal 13px "Rubik", sans-serif;
  }
}

.loadingMaskWrapperForHtmlElements {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */

  justify-content: center;
  align-items: center;

  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none !important;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: default;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  .loading {
    padding: 8px;
    border-radius: 3px;
  }
  .loadingImg {
    // width: 100px;
    height:50px
  }
  .loadingImageWrapper {
    padding: 0 0 4px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center 0;
  }
  .loadingText {
    color: #ffff;
    font: normal 13px "Rubik", sans-serif;
  }
}

