.auth-page {
  height: 80vh;

  .login-btn{
    width: -webkit-fill-available;

  }

  .left-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $base-light-gray;
  }

  .right-column {
    // display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    // background-color: $white;

    @media (max-width: breakpoint-max(md)) {
      display: none;
    }
  }
.login-text{
  color: #7F838A;
}
.input-icons i {
  position: absolute;
  right: 10%;
  bottom: auto;
}
.logo-power{
  color: $gray-dark;
  // margin-left: 90px;
  font:$login-font
}
.forgot-text{
  font:$forgot-font
}
.login-message-fonts{
  border-radius: 5px;
  color: #ffffff;
  font:$forgot-font
}
.authentication-text{
  font: normal normal medium 14px/21px Poppins;
  color: #7F838A;
}
}

.input-square{
 
  background-color: #F7F9FB;
  height: 60px;
  text-align: center;
  font-weight: 500;
  font-size: x-large;
  font-family: 'Poppins';

}
.widget-auth {
  width: 448px;
  box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);

  @media (max-width: breakpoint-max(xs)) {
    width: 100vw;
  }

  .auth-header {
    font-size: $headline-large;
    font-weight: $font-weight-semi-bold;
  }

  .logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-left: 12px;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  a {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

.auth-info {
  padding: 18px 4px;
  color: $gray-dark;
  border-radius: .5rem;
  font: $permissions-font;
  font-weight: $font-weight-semi-bold
  

}

// .form-control {
//   color: $base-dark-gray;
// }

.dividing-line {
  display: flex;
  flex-direction: row;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.dividing-line:before,
.dividing-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $base-middle-gray;
  margin: auto;
}

.social-label {
  margin-right: 1rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

.socials {
  a {
    margin: 0 .5rem;
  }
}
.jazzLogo{
  height: 80px;
  margin-right: 15px;
}
