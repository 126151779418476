@import "../../assets/sass/importFiles";
.table-record-select {
  min-width: 75px;
  height: 2.1rem;
  border: 1px transparent;
  border-radius: 5px;
  text-align-last: center;
  // background: $primaryColor;
  // color: $white;
  margin-left: 0.5rem;
}
.theader-btn {
  float: right;
  border: 1px transparent;
  margin-right: 1rem;
  background-color: $primaryColor2;
  border-radius: 5px;
  width: 125px;
  height: 2.1rem;
  color: $primaryColor1;
  a {
    color: $primaryColor1;
    text-decoration: none;
  }
}
.table-wrapper {
  margin: 10px;
  // table style
  .table-header {
    // display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.8em;
  }

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }
  table tr th {
    padding: 0.625em;
  }
  table tr td {
    background: #F7F8FB;
  }
  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
  }
  table tr th {
    padding: 0.625em;
  }

  table th,
  table td {
    text-align: center;
  }

  table th {
    font-size: 0.82em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  table th {
    font-size: 0.8em;
  }
  @media screen and (max-width: 767px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 13px;
      text-align: right;
    }

    table td::before {
      /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
  .td-img-btn {
    width: 40px;
    padding: 10px;
    max-width: 40px;
    &:hover {
      width: 42px;
    }
  }

  .td-notapprove-btn {
    content: url("../../assets/table/InActive.svg");
    padding: 0px;
  }
  .td-approve-btn {
    content: url("../../assets/table/Active.svg");
    padding: 0px;
  }


  .AddBtn-Approve {
    width: 40px;
    outline: none;
    padding: 0;
    height: 37px;
    margin-left: 10px;
    float: right;
    border: none;
    &:before {
      vertical-align: middle;
      margin: -1px 0px 0 1px;
    }
  }
  .img-btn-col {
    width: 100%;
    text-align: center;
  }

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .image {
    width: 300px;
  }
}
 .actionHead{
  text-align: center;
  margin: 12px;
 }
 .actionHeading{
  text-align: center;


 }

.flex-parent {
  display: flex;
}
.flex-child {
  margin-left: auto;
  order: 2;
}

.table-container {
  position: sticky;
  top: 0px;
  z-index: 10;
}
.cursor-pointer{
  cursor: pointer;
}