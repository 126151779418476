@import "../../styles/helpers";
.detectionImage {
  width: 20.25rem;
  height: 11.438rem;
}

.profileImage {
  height: 8.75rem !important;
  width: 8.75rem !important;
  object-fit: cover;
}
.profileImageSmall {
  height: 4.75rem !important;
  width: 4.75rem !important;
  object-fit: cover;
}
.react-datepicker__triangle{
  position: absolute;
  left: -85px!important;
  transform: translate(240px, 0px);
}

.input-icons i {
  position: absolute;
  right: 2%;
  margin-top: 7px;
}

.input-text {
  width: 100%;
  border:#D1D4D9 1px solid;
  border-radius: 3px;
  outline: none;
  padding: 6px 10px;
  height: 38px;
  font-size: $font-size-initial;
  background: #F7F9FB 0% 0% no-repeat padding-box;
  font-family: $Poppins; 
  font-weight: normal;
  color:$fontColorInput;
  margin-bottom: 10px;
  min-width: 0;
}

.calendar-date-range {
  width: 100%;
  &::after {
    content: "";
    background: url("../../assets/others/calendarIcon.svg ") no-repeat 0 center whitesmoke;
    width: 22px;
    height: 19px;
    position: absolute;
    right: 10px;
    padding-top: 15px;
    margin-top: 10px;
    pointer-events: none;
  }

}
/////React Date Picker//////
.react-datepicker-wrapper {
  display: block;
  position: initial;
  right: 0;
  width: 100%;
  padding-right: 8px;
  margin-bottom: 5px;
  &:before {
    content: "";
    background: url("../../assets/others/calendarIcon.svg") no-repeat 0 center;
    width: 30px;
    height: 18px;
    position: absolute;
    right: 1.5rem;
    margin-top: 10px;
    z-index: 2;
    pointer-events: none;

  }
  input {
    line-height: normal;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    height: 35px;
    width: 100%;
  }
}
///Staric on input field
.required:after {
  content:" *";
  color: red;
}
.inline-field-validation{
  color: $orange-dark;
}

.custom-file-upload {
  border: 2px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: white;
  border-color: #2196F3;
  color: dodgerblue;
  border-radius: 6px;
  font-size: 0.875rem;
  width: 10rem;
  text-align: center;
}

.captureImageButton {
  height: 2.3rem;
}
//selct input////
.select-input {
  margin-bottom: 10px;
  margin-top: 0px;
  width: 100%;

  &.inValid {
    margin-top: 0px;
    border: 1px solid $red;
    border-radius: 4px;
  }

}
.input-text-field {
  width: 100%;
  border: 1px solid $fieldsBorderColor;
  border-radius: 3px !important;
  outline: none;
  padding: 6px 10px;
  height: 35px;
  font: normal normal normal 15px/25px Poppins;
  color: $fieldsTextColor;
  margin-bottom: 10px;
  min-width: 0;
  &.inValid {
    border: 1px solid $red;
  }
}

.custom-file-upload {
  display: flex;
  border: 2px solid #ccc;
  color: blue;
  display: inline-block;
  text-align: center;
  width: 12.25rem !important;

}
//form row and label
.form-row {
  min-width: 125px;
  display: flex;
  color: $gray;
  font-size: 13px;
}
.form-label {
  min-width: 125px;
  font-size: 13px;
  // margin-top: 8px;
  display: flex;
  color: $font-label-color;
  // @include breakpoint(screen767) {
  //   flex-flow: column;
  // }
}
.form-label-row {
  cursor: pointer;
  min-width: auto;
  background-color: white;
  white-space: nowrap;
  border-color: #2196F3;
  margin-right: 5px;
  color: dodgerblue;
  font-size: 13px;
  border-radius: 6px;
  margin-top: 8px;
  font-size: 0.875rem;
  padding-left: 10px;
  width: 10rem;
  display: flex;
  text-align: center;
  color: blue;
}
.permission-label {
  font-size: 18px/27px;
  // margin-top: 8px;
  display: flex;
  color: $font-label-color;
  font-family: 'Poppins';

}
.bulkButton {
  border: none !important;
  
  // background-color: white !important;
}
.dropdown-menu{
  min-width: 0!important;
}
.dropdown-border{
  border:3px solid #286AD9!important;
}

.collapseBarStyle {
  margin-bottom: 1rem;
  background-color: '#E1E6EB 0% 0% no-repeat padding-box;'
}
.fileUploadContainer {
  border: 2px solid grey !important;
  border-style: dashed !important;
  position: relative;
  margin: 25px 0 15px;
  border: 2px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.formField {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.deleteIcon {
  cursor: pointer;
  opacity: 0.8;
}

.deleteIcon:hover {
  transform: scale(1.2);
}

.formFieldImagesUpload {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

}

.inputLabelImagesUpload {
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
}

.dragDropTextImagesUpload {
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
}

.uploadFileBtnImagesUpload {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #3498db;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #3498db;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 45%;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;
}

.articleImagesUpload {
  margin-bottom: 35px;
}

.articleImagesUpload span {
  font-size: 14px;
}
.auth-reset {
  font-size: $headline-large;
  font-weight: $font-weight-semi-bold;
}
.auth-reset-info {
  padding: 18px 4px;
  color: $gray-dark;
  border-radius: .5rem;
  font: $permissions-font;
  font-weight: $font-weight-semi-bold
  

}
.login-btn{
  width: -webkit-fill-available;

}
.previewListImagesUpload {
  margin-top: 1rem !important;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
}

.fileMetaDataImagesUpload {
  /* display: ${(props) => (props.isImageFile ? "none" : "flex")}; */
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);

  /* aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  } */
}

.PreviewContainer {
  padding: 0.25rem;
  width: 20%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 50px;

  /* &:hover {
    opacity: 0.55;

    
  } */

  /* & > div:first-of-type {
    height: 100%;
    position: relative;
  } */

  @media only screen and (max-width: 750px) {
    width: 25%;
  }

  @media only screen and (max-width: 500px) {
    width: 50%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
}

.PreviewContainer:hover {
  opacity: 0.55;
}

.PreviewContainer div:first-of-type {
  height: 100%;
  position: relative;
}

.ImagePreview {
  border-radius: 6px;
  width: 100%;
  height: 100%;

}

.listGroup li {
  list-style-type: circle;
  color: grey;
  font-weight: bold;
}
.form-heading{
font-size: $formHeading;
letter-spacing: 0px;
// color: #222730;
opacity: 1;
}
.breadcrum-text{
  color: #222730;
}

.cardBodyBackground{
  background: #F2F4F6 0% 0% no-repeat padding-box;
}
.centeredImage{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(114, 114, 114, 0.8); 
}
@media only screen and (max-width: 400px) {
.full-width-column{
width: 100%;
margin-bottom: 4px;
}
.button-full-width{
  width: 100%;
}
.add-full-width{
  width: 100%;
  margin-top: 4px;
}
.image-margin{
  margin-left: 1rem!important;
}
.image-margin-upload{
  margin-left: 0rem!important;

}
.custom-file-upload2 {
  width: 18.35rem !important;

}
.custom-file-upload4 {
  width: 17.35rem !important;

}
 
.browse-image-layout{
  margin-top: 8rem !important;
  margin-left: -16px;
}

}
@media only screen and (min-width: 960px) {

.image-margins{
  margin-left: 2rem!important;
}
.upload-image-res{
  margin-left: 50px;
    width: 56px !important;
    margin-top: 26px;
}
.btn-widths{
  width: 12.5rem!important;
}
}